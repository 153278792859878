.card {
    background: #FEFEFE;
    border-radius: 9px;
    border-color: lightgray;
    box-shadow: 0px 1px 2px 0px hsla(0, 0%, 0%, 0.2);
    overflow: hidden;
    margin-top: 0px;
    margin-bottom: 4px;
    margin-right: 0px;
    margin-left: 0px;
    max-width: 96vw;
}