::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 12px;
    /* Width of scrollbar */
    height: 12px;
    /* Height of scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    /* Color of the scrollbar thumb */
    border-radius: 6px;
    /* Make the scrollbar round */
}

.content-routes {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    padding-top: 74px;
}

.user-input {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    padding-top: 10px;
}

.sample-message {
    border: .2px solid gainsboro;
    white-space: nowrap !important;
    text-align: center;
    width: 100% !important;
}