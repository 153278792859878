::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 12px;
    /* Width of scrollbar */
    height: 12px;
    /* Height of scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
    /* Color of the scrollbar thumb */
    border-radius: 6px;
    /* Make the scrollbar round */
}

.details-box {
    border: .5px solid gray;
    margin-top: 6px;
    height: 60vh;
    width: 100% !important;
    overflow: scroll;
}

.table-detail {
    border: .2px solid gainsboro;
    white-space: nowrap !important;
}

.sort-line {
    display: flex;
    justify-content: space-between;
}

.mobile-col-info{
   font-size: 1rem;
   overflow-wrap: break-word !important;
   white-space: normal !important;
}

.sort-div{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.sort-button{
    margin-bottom: 5px;
    border: 1px solid #ced4da !important;
    outline: none !important;
    background-color: transparent;
    border-radius: 5px; 
    height: 38px;
}
.sort-button:active, .sort-button:focus{
    background-color: transparent !important;
}